interface Coordinates {
  _sw: { lng: number; lat: number };
  _ne: { lng: number; lat: number };
}

export function getCenterByBound(bounds: Coordinates) {
  const centerLat = (bounds._sw.lat + bounds._ne.lat) / 2;
  const centerLng = (bounds._sw.lng + bounds._ne.lng) / 2;
  return { lat: centerLat, lng: centerLng };
}
