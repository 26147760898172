import { AppProps } from 'next/app';
import Head from 'next/head';

import { ApolloWrapper } from '../graphql/apollo-client';
import './styles.css';
import Script from 'next/script';
import { isTrackingEnabled, intercomScript } from '@requity-homes/utils';
import mixpanel from 'mixpanel-browser';
import { segmentSnippet, Track } from '../analytics';
import { HubSpotTracking } from '@requity-homes/component-library';
import { customAnalyticsScript } from '../utils/custom-analytics-script';

const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ApolloWrapper>
      <Head>
        <link rel="icon" href="/listings/favicon-32.png" sizes="32x32" />
        <link rel="icon" href="/listings/favicon-128.png" sizes="128x128" />
        <link rel="icon" href="/listings/favicon-180.png" sizes="180x180" />
        <link rel="icon" href="/listings/favicon-192.png" sizes="192x192" />
      </Head>
      <Script id="intercom-script">
        {typeof window !== 'undefined' && window.parent === window
          ? intercomScript(process.env.NEXT_PUBLIC_INTERCOM_APP_ID)
          : null}
      </Script>
      {isTrackingEnabled() && (
        <Script
          id="segment-script"
          dangerouslySetInnerHTML={{ __html: segmentSnippet() }}
        />
      )}
      <Script id="custom-analytics-script">
        {typeof window !== 'undefined' && window.parent === window
          ? customAnalyticsScript()
          : null}
      </Script>

      {isTrackingEnabled() &&
        mixpanelToken &&
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)}
      <Track></Track>
      <Component {...pageProps} />
      <HubSpotTracking isTrackingEnabled={true} />
    </ApolloWrapper>
  );
}
