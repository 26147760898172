import { bbox, circle, point } from '@turf/turf';

export function getBoundByCenter(
  center: { lat: number; lng: number },
  radius = 1,
) {
  const centerPoint = point([center.lng, center.lat]);
  const circleAroundCenter = circle(centerPoint, radius, {
    units: 'kilometers',
  });
  return bbox(circleAroundCenter);
}
