import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
// import { Auth } from 'aws-amplify';
// import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
// import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { setContext } from '@apollo/client/link/context';

const url = process.env.NEXT_PUBLIC_APPSYNC_URL;
const region = process.env.NEXT_PUBLIC_AWS_REGION;
// const auth: AuthOptions = {
//   type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
//   jwtToken: async () => {
//     const session = await Auth.currentSession();
//     return session.getIdToken().getJwtToken();
//   },
// };

// const link = ApolloLink.from([
//   createAuthLink({ url, region, auth }),
//   createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
// ]);

const httpLink = new HttpLink({ uri: url });

const authLink = setContext((_, { headers }) => {
  const apiKey = process.env.NEXT_PUBLIC_APPSYNC_API_KEY;
  return {
    headers: {
      ...headers,
      'x-api-key': apiKey,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const ApolloWrapper = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
