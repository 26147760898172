import { cleanStreetName } from '../clean-street-name/clean-street-name';
import { getListingsEnvironmentBaseUrl } from '../environment';
import { RepliersStateToAbbreviatedProvinceMap } from '../province-to-abbreviated-province-map';

export const generateListingUrl = (listing: any, stage: string) => {
  const baseUrl = getListingsEnvironmentBaseUrl(stage);

  const cityName =
    listing.address.city.toLowerCase() === 'sault ste marie'
      ? 'sault ste. marie'
      : listing.address.city.toLowerCase();

  const provinceAbbreviation =
    RepliersStateToAbbreviatedProvinceMap[listing.address.state];

  const listingUrl = `${baseUrl}/${provinceAbbreviation}/${cityName.replace(
    / /g,
    '-',
  )}/rent-to-own/${listing.mlsNumber.toLowerCase()}/${cleanStreetName(
    listing?.address.streetName,
  )}`;

  return listingUrl;
};
