export const listingCities: Record<string, string> = {
  Edmonton: 'Edmonton',
  'North Bay': 'North Bay',
  Regina: 'Regina',
  Saskatoon: 'Saskatoon',
  'Sault Ste. Marie': 'Sault Ste. Marie,Sault Ste Marie',
  'Spruce Grove': 'Spruce Grove',
  Sudbury: 'Sudbury',
  'Thunder Bay': 'Thunder Bay',
  'St. Albert': 'St. Albert',
  'Stony Plain': 'Stony Plain',
  'Sherwood Park': 'Sherwood Park',
  Beaumont: 'Beaumont',
  Leduc: 'Leduc',
  Martensville: 'Martensville',
  Warman: 'Warman',
  'Red Deer': 'Red Deer',
  Lethbridge: 'Lethbridge',
  'Grande Prairie': 'Grande Prairie',
  Airdrie: 'Airdrie',
  'Prince Albert': 'Prince Albert',
  'Moose Jaw': 'Moose Jaw',
};
