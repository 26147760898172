export function getBoundPolygon(
  sw: { lng: number; lat: number },
  ne: { lng: number; lat: number },
) {
  const boundPolygon = {
    type: 'Polygon',
    coordinates: [
      [
        [sw.lng, ne.lat],
        [ne.lng, ne.lat],
        [ne.lng, sw.lat],
        [sw.lng, sw.lat],
        [sw.lng, ne.lat],
      ],
    ],
  };
  return boundPolygon;
}
