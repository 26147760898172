const COOKIE_UTM_KEY = '_cookie_utm_key';

export const customAnalyticsScript = () => `
  const utmCookiesExist = ${getCookie(COOKIE_UTM_KEY)};

  const SOURCES = {
    FACEBOOK: 'Facebook',
    GOOGLE: 'Google',
    YAHOO: 'Yahoo',
    BING: 'Bing',
    KIJIJI: 'Kijiji',
    DEFAULT: 'requityhomes.com/listings'
  };

  const MEDIUM = 'Organic';
  const CAMPAIGN = 'Listing Page';

  if(!utmCookiesExist) {
    const urlParams = new URLSearchParams(window.location.search);
  
    const utmSource = urlParams.get('utm_source') || getUtmSource(document.referrer); 
    const utmMedium = urlParams.get('utm_medium') || getUtmMedium(document.referrer) 
    const utmCampaign = urlParams.get('utm_campaign') || getUtmCampaign(document.referrer); 
    const utmContent = urlParams.get('utm_content') || getUtmContent(document.referrer); 
    const utmTerm = urlParams.get('utm_term') || getUtmTerm(document.referrer);
  
    const utmData = {
      cookieInitialRef: window.location.href
    };
    
    if(utmSource) {
      utmData.utmSource = utmSource;
    }
    if(utmMedium) {
      utmData.utmMedium = utmMedium;
    }
    if(utmCampaign) {
      utmData.utmCampaign = utmCampaign;
    }
    if(utmContent) {
      utmData.utmContent = utmContent;
    }
    if(utmTerm) {
      utmData.utmTerm = utmTerm;
    }
  
    if(Object.keys(utmData).length > 0) {
      const date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      document.cookie = "_cookie_utm_key" + "=" + JSON.stringify(utmData) + "; " + expires + "; path=/; domain=.requityhomes.com;";
    }
  }
  
  function getDomainFromUrl(url) {
    if(!url) return '';

    try {
      // Create a new URL object
      const urlObj = new URL(url);

      // Return the hostname (domain name) from the URL object
      return urlObj.hostname;
    } catch (error) {
      // Handle any errors (e.g., if the URL is invalid)
      console.error('Invalid URL:', error);
      return '';
    }
  }

  function getCityFromUrl() {
    try {
      // Create a new URL object
      const urlObj = new URL(window.location.href);
      
      // Get the pathname from the URL object
      const path = urlObj.pathname;
      
      // Split the pathname by '/'
      const parts = path.split('/');
      
      // Ensure that the path has enough parts to access the desired element
      if (parts.length >= 4) {
          return parts[2];
      }
      return null;
    } catch (error) {
      // Handle any errors (e.g., if the URL is invalid)
      console.error('Invalid URL:', error);
      return null;
    }
  }

  function getUtmSource(referrer) {
    const sourceMap = {
      'facebook': SOURCES.FACEBOOK,
      'google': SOURCES.GOOGLE,
      'yahoo': SOURCES.YAHOO,
      'bing': SOURCES.BING
    };

    for (let key in sourceMap) {
      if (referrer.includes(key)) {
        return sourceMap[key];
      }
    }

    return referrer ? getDomainFromUrl(referrer) : SOURCES.DEFAULT;
  }

  function getUtmMedium(referrer) {
    return referrer ? MEDIUM : null;
  }

  function getUtmCampaign(referrer) {
    return referrer ? CAMPAIGN : null;
  }

  function getUtmContent(referrer) {
    return referrer ? getCityFromUrl() : null;
  }

  function getUtmTerm(referrer) {
    if(!referrer) return null;
    const urlSections = window.location.href.split('/');
    return urlSections[urlSections.length -1].split('?')[0]
  }
`;

function getCookie(name) {
  // Create a regular expression to match the cookie name
  const nameEQ = name + '=';
  // Split the document.cookie string into individual cookies
  const cookies = document.cookie.split(';');

  // Loop through each cookie
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    // Remove leading spaces from the cookie string
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    // Check if this cookie starts with the desired name
    if (cookie.indexOf(nameEQ) === 0) {
      // Return the cookie value (the part after the '=')
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  // If the cookie was not found, return null
  return null;
}
