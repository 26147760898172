interface CleanedValues {
  [key: string]: string;
}

export const removeEmptyValues = (obj: any) => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(
    ([key, value]) => value !== null && value !== undefined && value !== '',
  );

  const result = Object.fromEntries(filteredEntries) as CleanedValues;

  return result;
};
