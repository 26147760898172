import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CommonTrackingEvents,
  trackEvent,
} from '@requity-homes/component-library';

const track = (path: string, params?: Record<string, string>) => {
  trackEvent(CommonTrackingEvents.PageView, {
    path,
    ...params,
  });
};

export function Track() {
  const router = useRouter();
  const previousPathRef = useRef('');

  const handleRouteChange = useCallback((path: string) => {
    if (path === previousPathRef.current) {
      return;
    }
    previousPathRef.current = path;
    const city = decodeURIComponent(
      window.location.pathname.split('/')[1]?.split('?')[0],
    );
    track(path, {
      city,
    });
  }, []);

  // Track initial page load
  useEffect(() => {
    handleRouteChange(router.asPath);
  }, []);

  // Track route changes after initial load
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return null;
}
